import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { useUserPermissions } from "../../hooks/useUserPermissions";
import { Permissions } from "../../constants/permissionsConstants";

interface IQuestionActionsProps {
    openModal: () => void;
}

const QuestionActionsComponent: React.FC<IQuestionActionsProps> = ({ openModal }) => {
    const { userHasPermissions } = useUserPermissions();

    const rightSideActions = (
        <>{userHasPermissions([Permissions.writeQuestions]) && <Button label="Add" onClick={openModal} rounded icon="pi pi-plus"></Button>}</>
    );

    return <Toolbar end={rightSideActions}></Toolbar>;
};

export default QuestionActionsComponent;
