import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { IQuestionCategory } from "../../models/questionCategory";
import { getAvailableLevels } from "../../utils/questionUtils";
import { useState } from "react";

interface IQuestionFormProps {
    isEdit: boolean;
    questionId?: string;
    questionValue: string;
    includeTranslation: boolean;
    questionTranslation: string | undefined;
    questionLevel: number | undefined;
    questionCategory: IQuestionCategory | undefined;
    availableCategories: IQuestionCategory[];
    setQuestionValue: (value: string) => void;
    setIncludeTranslation: (value: boolean) => void;
    setQuestionTranslation: (value: string) => void;
    setQuestionLevel: (value: number) => void;
    setQuestionCategory: (value: IQuestionCategory) => void;
    resetFormAndCloseModal: () => void;
    onSubmit: () => void;
}

const QuestionFormComponent: React.FC<IQuestionFormProps> = ({
    isEdit,
    questionId,
    questionValue,
    includeTranslation,
    questionTranslation,
    questionLevel,
    questionCategory,
    availableCategories,
    setQuestionValue,
    setIncludeTranslation,
    setQuestionTranslation,
    setQuestionLevel,
    setQuestionCategory,
    resetFormAndCloseModal,
    onSubmit,
}) => {
    const [isFormValid, setIsFormValid] = useState(true);
    const availableLevels = getAvailableLevels();

    const validateAndSubmit = (event: any) => {
        event.preventDefault();
        const isValid = validateForm();

        if (isValid) {
            setIsFormValid(true);
            onSubmit();
        } else {
            setIsFormValid(false);
        }
    };

    const validateForm = (): boolean => {
        if (
            questionValue.trim().length === 0 ||
            (includeTranslation && questionTranslation?.trim().length === 0) ||
            !questionLevel ||
            !questionCategory
        ) {
            setIsFormValid(false);
            return false;
        }

        setIsFormValid(true);
        return true;
    };

    const closeModal = (event: any) => {
        event.preventDefault();
        resetFormAndCloseModal();
    };

    return (
        <form onSubmit={validateAndSubmit} className="flex flex-column gap-4">
            {isEdit && (
                <span className="p-float-label">
                    <InputText id="questionId" name="questionId" value={questionId} className="w-full" disabled />
                    <label htmlFor="input_questionId">Id</label>
                </span>
            )}

            <span className="p-float-label">
                <InputText
                    id="question"
                    name="question"
                    value={questionValue}
                    className="w-full"
                    onChange={(e) => {
                        setQuestionValue(e.target.value);
                    }}
                />
                <label htmlFor="input_question">Question</label>
            </span>

            <div className="flex align-items-center">
                <Checkbox
                    inputId="includeTranslationCheckbox"
                    onChange={(e) => setIncludeTranslation(e.checked ?? false)}
                    checked={includeTranslation}
                ></Checkbox>
                <label htmlFor="includeTranslationCheckbox" className="ml-2">
                    Include translation
                </label>
            </div>

            {includeTranslation && (
                <span className="p-float-label">
                    <InputText
                        id="questionTranslation"
                        name="questionTranslation"
                        value={questionTranslation}
                        className="w-full"
                        onChange={(e) => {
                            setQuestionTranslation(e.target.value);
                        }}
                    />
                    <label htmlFor="input_questionTranslation">Question translation</label>
                </span>
            )}

            <div className="flex gap-5 justify-content-between">
                <Dropdown
                    value={questionLevel}
                    onChange={(e) => {
                        setQuestionLevel(e.value);
                    }}
                    options={availableLevels}
                    optionLabel="display"
                    placeholder="Select a Level"
                    className="w-5"
                />
                <Dropdown
                    value={questionCategory}
                    onChange={(e) => {
                        setQuestionCategory(e.value);
                    }}
                    options={availableCategories}
                    optionLabel="description"
                    placeholder="Select a category"
                    className="w-5"
                />
            </div>

            {!isFormValid && <span className="text-red-600">Something's missing</span>}
            <div className="flex gap-5 justify-content-center pt-3">
                <Button type="submit" label={isEdit ? "Save" : "Submit"} rounded severity="success" />
                <Button type="button" label="Cancel" rounded severity="secondary" onClick={closeModal} />
            </div>
        </form>
    );
};

export default QuestionFormComponent;
