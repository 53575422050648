import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/QOTD-new-logo.svg";

const MainHeaderComponent: React.FC = () => {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const navigate = useNavigate();

    return (
        <div className="flex justify-content-between bg-orange-100 z-1 p-3 pr-6 pl-6 font-bold qotd-box-shadow" data-testid="main-header">
            <div className="flex align-items-center gap-3">
                {isAuthenticated && <img src={logo} alt={"QOTD"} onClick={() => navigate("/")} className="h-4rem w-auto cursor-pointer" />}
                QOTD - Admin
            </div>
            <div className="flex align-items-center gap-3">
                {!isAuthenticated && <Button role="button" aria-label="Login" label="Login" rounded onClick={() => loginWithRedirect()}></Button>}
                {isAuthenticated && (
                    <Button label="Logout" rounded onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}></Button>
                )}
            </div>
        </div>
    );
};

export default MainHeaderComponent;
