import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { useGetAllSurveys } from "../../api/apiClient";
import { useAccessToken } from "../../hooks/useAccessToken";
import { ISurvey } from "../../models/survey/survey";
import { Button } from "primereact/button";
import SurveyAnswersModalComponent from "./surveyAnswersModal";

const SurveyListComponent: React.FC = () => {
    const token = useAccessToken();
    const [selectedSurvey, setSelectedSurvey] = useState<ISurvey | undefined>(undefined);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [expandedRows, setExpandedRows] = useState<any>(null);
    const { isError, isLoading, data } = useGetAllSurveys(token);

    const rowExpansionTemplate = (data: ISurvey) => {
        return (
            <div className="max-h-8rem overflow-auto">
                <ul>
                    {data.surveyOptions.map((option) => {
                        return <li key={option.order}>{option.value + " - " + option.description}</li>;
                    })}
                </ul>
            </div>
        );
    };

    const actionsTemplate = (survey: ISurvey) => {
        return <Button severity="info" icon="pi pi-search-plus" title="View answers" rounded onClick={() => openSurveyAnswersModal(survey)}></Button>;
    };

    const allowExpansion = (rowData: ISurvey) => {
        return rowData.surveyOptions.length > 0;
    };

    const openSurveyAnswersModal = (survey: ISurvey) => {
        setSelectedSurvey(survey);
        setIsModalVisible(true);
    };

    const clearSurveySelection = () => {
        setIsModalVisible(false);
        setSelectedSurvey(undefined);
    };

    return (
        <>
            {isError ? (
                <label>Oops, something failed</label>
            ) : (
                <div className="p-0 border-round border-2 border-orange-200" data-testId="scheduled-questions-list">
                    <DataTable
                        value={data}
                        loading={isLoading}
                        showGridlines
                        stripedRows
                        paginator
                        scrollable
                        scrollHeight="62vh"
                        rows={10}
                        rowsPerPageOptions={[10, 25, 50]}
                        tableStyle={{ minWidth: "50%" }}
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        rowExpansionTemplate={rowExpansionTemplate}
                        dataKey="id"
                    >
                        <Column expander={allowExpansion} style={{ width: "5rem" }} />
                        <Column field="description" filter filterPlaceholder="Search by description" header="Description"></Column>
                        <Column field="type.description" sortable header="Type"></Column>
                        <Column field="" header="Actions" align={"center"} body={actionsTemplate}></Column>
                    </DataTable>
                </div>
            )}
            <SurveyAnswersModalComponent
                survey={selectedSurvey}
                isVisible={isModalVisible}
                onModalClose={clearSurveySelection}
            ></SurveyAnswersModalComponent>
        </>
    );
};

export default SurveyListComponent;
