import { useQuery } from "react-query";
import axios from "axios";
import { IQuestionCategory } from "../../models/questionCategory";

const basePath = `${process.env.REACT_APP_API_BASE_PATH ?? ""}/category`;

export const useGetAllQuestionCategories = (token: string) => {
    const apiPath = `${basePath}`;
    const queryKey = `getAllQuestionCategories-${token}`;
    return useQuery<IQuestionCategory[]>(
        queryKey,
        () => {
            if (token) {
                return axios
                    .get<IQuestionCategory[]>(apiPath, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((res: any) => res.data);
            }

            return Promise.resolve([]);
        },
        {
            refetchOnWindowFocus: false,
        }
    );
};
