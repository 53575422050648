import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

export const useAccessToken = () => {
    const [token, setToken] = useState("");
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        async function getToken() {
            const newToken = await getAccessTokenSilently();
            setToken(newToken);
        }

        if (!token) {
            getToken();
        }
    }, [token, setToken, getAccessTokenSilently]);

    return token;
};
