import { useQuery } from "react-query";
import axios from "axios";
import { ISurvey } from "../../models/survey/survey";
import { ISurveyAnswer } from "../../models/survey/surveyAnswer";

const basePath = `${process.env.REACT_APP_API_BASE_PATH ?? ""}/survey`;

export const useGetAllSurveys = (token: string) => {
    const apiPath = `${basePath}`;
    const queryKey = `getAllSurveys-${token}`;
    return useQuery<ISurvey[]>(
        queryKey,
        () => {
            if (token) {
                return axios
                    .get<ISurvey[]>(apiPath, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((res: any) => res.data);
            }

            return Promise.resolve([]);
        },
        {
            refetchOnWindowFocus: false,
        }
    );
};

export const useGetSurveyAnswers = (token: string, surveyId: string) => {
    const apiPath = `${basePath}/answers/${surveyId}`;
    const queryKey = `getSurveyAnswers-${surveyId}`;
    return useQuery<ISurveyAnswer[]>(
        queryKey,
        () => {
            if (token) {
                return axios
                    .get<ISurveyAnswer[]>(apiPath, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((res: any) => res.data);
            }

            return Promise.resolve([]);
        },
        {
            refetchOnWindowFocus: false,
        }
    );
};
