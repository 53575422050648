import "./App.css";
import "/node_modules/primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-orange/theme.css";
import { PrimeReactProvider } from "primereact/api";
import DefaultLayoutComponent from "./components/layout/defaultLayout";
import { useAuth0 } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import QuestionListComponent from "./components/questionsList/questionsList";
import SurveyListComponent from "./components/surveysList/surveyList";
import SchedulerDashboardComponent from "./components/scheduler/schedulerDashboard";

const App = () => {
    const { isLoading, error } = useAuth0();
    const queryClient = new QueryClient();
    const router = createBrowserRouter([
        {
            path: "/",
            element: <DefaultLayoutComponent />,
            children: [
                { path: "/", element: <QuestionListComponent></QuestionListComponent> },
                { path: "/surveys", element: <SurveyListComponent></SurveyListComponent> },
                { path: "/scheduler", element: <SchedulerDashboardComponent></SchedulerDashboardComponent> },
                { path: "/unauthorized", element: <div>You do not have permission to see this</div> },
            ],
        },
    ]);

    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <QueryClientProvider client={queryClient}>
            <PrimeReactProvider>
                <RouterProvider router={router} />
            </PrimeReactProvider>
        </QueryClientProvider>
    );
};

export default App;
