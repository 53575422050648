import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useState } from "react";
import { ITeam } from "../../models/team";
import { Calendar } from "primereact/calendar";
import AskForFeedbackSubFormComponent from "./askForFeedbackSubForm";
import { JOB_SCHEDULER_JOB_TYPE_ASK_FOR_FEEDBACK, getAvailableJobTypes } from "../../utils/scheduleUtils";
import { IDropdownItem } from "../../models/common/dropdownItem";

interface IScheduleFormProps {
    isEdit: boolean;
    teamId: string | undefined;
    channelId: string | undefined;
    jobTime: Date | null | undefined;
    jobType: string | undefined;
    jobParams: string | undefined;
    availableTeams: ITeam[] | undefined;
    availableChannels: string[] | undefined;
    setTeamId: (value: string) => void;
    setChannelId: (value: string) => void;
    setJobTime: (value: Date | null) => void;
    setJobType: (value: string) => void;
    setJobParams: (value: string | undefined) => void;
    resetFormAndCloseModal: () => void;
    onSubmit: () => void;
}

const ScheduleFormComponent: React.FC<IScheduleFormProps> = ({
    isEdit,
    teamId,
    channelId,
    jobTime,
    jobType,
    jobParams,
    availableTeams,
    availableChannels,
    setTeamId,
    setChannelId,
    setJobTime,
    setJobType,
    setJobParams,
    resetFormAndCloseModal,
    onSubmit,
}) => {
    const [isFormValid, setIsFormValid] = useState(true);
    const availableJobTypes: IDropdownItem[] = getAvailableJobTypes();
    const availableTeamsAsDropdownItems = availableTeams?.map((team) => {
        return {
            value: team.id,
            display: team.hasCustomName ? `${team.name} (${team.id})` : team.id,
        };
    });
    const isJobTypeEnabled = teamId !== undefined && channelId !== undefined && jobTime !== null;

    const validateAndSubmit = (event: any) => {
        event.preventDefault();
        const isValid = validateForm();

        if (isValid) {
            setIsFormValid(true);
            onSubmit();
        } else {
            setIsFormValid(false);
        }
    };

    const validateForm = (): boolean => {
        if (
            teamId === undefined ||
            channelId === undefined ||
            jobTime === null ||
            jobType === undefined ||
            (jobType === JOB_SCHEDULER_JOB_TYPE_ASK_FOR_FEEDBACK && jobParams === undefined)
        ) {
            setIsFormValid(false);
            return false;
        }

        setIsFormValid(true);
        return true;
    };

    const closeModal = (event: any) => {
        event.preventDefault();
        resetFormAndCloseModal();
    };

    const updateJobTypeValue = (value: string) => {
        setJobType(value);
        setJobParams(undefined);
    };

    return (
        <form onSubmit={validateAndSubmit} className="flex flex-column gap-4">
            <div className="flex gap-5 justify-content-between">
                <Dropdown
                    value={teamId}
                    onChange={(e) => {
                        setTeamId(e.value);
                    }}
                    options={availableTeamsAsDropdownItems ?? []}
                    optionLabel="display"
                    placeholder="Select a team id"
                    className="w-5"
                    disabled={isEdit}
                />
                <Dropdown
                    value={channelId}
                    onChange={(e) => {
                        setChannelId(e.value);
                    }}
                    options={availableChannels ?? []}
                    disabled={!teamId}
                    placeholder="Select a channel id"
                    className="w-5"
                />
                <Calendar placeholder="Select time" className="w-5" value={jobTime} onChange={(e) => setJobTime(e.value ?? null)} timeOnly />
            </div>
            <div className="flex gap-5 justify-content-between">
                <Dropdown
                    value={jobType}
                    onChange={(e) => {
                        updateJobTypeValue(e.value);
                    }}
                    disabled={!isJobTypeEnabled}
                    options={availableJobTypes}
                    optionLabel="display"
                    placeholder="Select a job type"
                    className="w-5"
                />
            </div>

            {jobType === JOB_SCHEDULER_JOB_TYPE_ASK_FOR_FEEDBACK && (
                <AskForFeedbackSubFormComponent
                    channelId={channelId}
                    jobParams={jobParams}
                    setJobParams={setJobParams}
                ></AskForFeedbackSubFormComponent>
            )}

            {!isFormValid && <span className="text-red-600">Something's missing</span>}
            <div className="flex gap-5 justify-content-center pt-3">
                <Button type="submit" label={isEdit ? "Save" : "Submit"} rounded severity="success" />
                <Button type="button" label="Cancel" rounded severity="secondary" onClick={closeModal} />
            </div>
        </form>
    );
};

export default ScheduleFormComponent;
