import { IQOTDJwtPayload } from "../models/security/qotdJwtPayload";
import { useAccessToken } from "./useAccessToken";
import { jwtDecode } from "jwt-decode";

export const useUserPermissions = () => {
    const token = useAccessToken();

    const userHasPermissions = (permissions: string[]): boolean => {
        if (token) {
            const decodedToken = jwtDecode<IQOTDJwtPayload>(token);

            return permissions.every((permission) => decodedToken.permissions.includes(permission));
        }

        return false;
    };

    return { userHasPermissions };
};
