import React from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useUserPermissions } from "../../hooks/useUserPermissions";
import { Permissions } from "../../constants/permissionsConstants";

const SideNavbarComponent: React.FC = () => {
    const navigate = useNavigate();
    const { userHasPermissions } = useUserPermissions();

    return (
        <div className="flex flex-column gap-3 bg-white z-0 p-4 w-full lg:w-2 qotd-box-shadow" data-testid="main-navbar">
            {userHasPermissions([Permissions.readAllQuestions, Permissions.readAllCategories]) && (
                <Button className="w-full" role="link" severity="info" label="Questions" rounded onClick={() => navigate("/")}></Button>
            )}
            {userHasPermissions([Permissions.readAllSurveys, Permissions.writeSurveys]) && (
                <Button className="w-full" role="link" severity="info" label="Surveys" rounded onClick={() => navigate("/surveys")}></Button>
            )}
            {userHasPermissions([Permissions.readAllSchedules, Permissions.writeSchedules]) && (
                <Button className="w-full" role="link" severity="info" label="Scheduler" rounded onClick={() => navigate("/scheduler")}></Button>
            )}
        </div>
    );
};

export default SideNavbarComponent;
