import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";

interface ISchedulerActionsProps {
    openModal: () => void;
}

const SchedulerActionsComponent: React.FC<ISchedulerActionsProps> = ({ openModal }) => {
    const rightSideActions = <Button label="Add" onClick={openModal} rounded icon="pi pi-plus"></Button>;

    return <Toolbar end={rightSideActions}></Toolbar>;
};

export default SchedulerActionsComponent;
