import { useQuery } from "react-query";
import axios from "axios";
import { ITeam } from "../../models/team";

const basePath = `${process.env.REACT_APP_API_BASE_PATH ?? ""}/team`;

export const useGetAllTeams = (token: string) => {
    const apiPath = `${basePath}`;
    const queryKey = `getAllTeams-${token}`;
    return useQuery<ITeam[]>(
        queryKey,
        () => {
            if (token) {
                return axios
                    .get<ITeam[]>(apiPath, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((res: any) => res.data);
            }

            return Promise.resolve([]);
        },
        {
            refetchOnWindowFocus: false,
        }
    );
};

export const useGetChannelsForTeam = (token: string, teamId?: string) => {
    const apiPath = `${basePath}/channels/${teamId}`;
    const queryKey = `getChannelsForTeam-${teamId}-${token}`;
    return useQuery<string[]>(
        queryKey,
        () => {
            if (token) {
                return axios
                    .get<string[]>(apiPath, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((res: any) => res.data);
            }

            return Promise.resolve([]);
        },
        {
            refetchOnWindowFocus: false,
            enabled: !!teamId,
        }
    );
};
