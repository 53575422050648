import { IDropdownItem } from "../models/common/dropdownItem";

export const JOB_SCHEDULER_JOB_TYPE_ASK_FOR_FEEDBACK = "askForFeedback";
export const JOB_SCHEDULER_JOB_TYPE_SEND_DAILY_QUESTION = "sendDailyQuestion";

export const JOB_SCHEDULER_CADENCE_ONCE = ".";
export const JOB_SCHEDULER_CADENCE_DAILY = "*";

export const getCadenceForJobType = (jobType: string | undefined): string => {
    switch (jobType) {
        case JOB_SCHEDULER_JOB_TYPE_ASK_FOR_FEEDBACK:
            return JOB_SCHEDULER_CADENCE_ONCE;
        case JOB_SCHEDULER_JOB_TYPE_SEND_DAILY_QUESTION:
            return JOB_SCHEDULER_CADENCE_DAILY;
        default:
            return JOB_SCHEDULER_CADENCE_ONCE;
    }
};

export const getAvailableJobTypes = (): IDropdownItem[] => {
    return [
        {
            value: JOB_SCHEDULER_JOB_TYPE_ASK_FOR_FEEDBACK,
            display: "Ask for feedback",
        },
        {
            value: JOB_SCHEDULER_JOB_TYPE_SEND_DAILY_QUESTION,
            display: "Send daily question",
        },
    ];
};
