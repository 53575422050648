import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useGetAllQuestions } from "../../api/apiClient";
import { useState } from "react";
import { IQuestion } from "../../models/question";
import QuestionActionsComponent from "./questionsActions";
import QuestionModalComponent from "./questionModal";
import { useAccessToken } from "../../hooks/useAccessToken";
import { Button } from "primereact/button";
import { useUserPermissions } from "../../hooks/useUserPermissions";
import { Permissions } from "../../constants/permissionsConstants";

const QuestionListComponent: React.FC = () => {
    const token = useAccessToken();
    const { userHasPermissions } = useUserPermissions();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState<IQuestion | undefined>(undefined);
    const isEdit = selectedQuestion !== undefined;
    const { isError, isLoading, data } = useGetAllQuestions(token);

    const openModalToEditQuestion = (question: IQuestion) => {
        setSelectedQuestion(question);
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setSelectedQuestion(undefined);
        setIsModalVisible(false);
    };

    const hasTranslationTemplate = (question: IQuestion) => {
        if (question.hasTranslation) {
            return <i className="pi pi-check text-green-300" style={{ fontSize: "1.5rem" }}></i>;
        }

        return <i className="pi pi-times text-red-300" style={{ fontSize: "1.5rem" }}></i>;
    };

    const actionsTemplate = (question: IQuestion) => {
        return (
            <>
                {userHasPermissions([Permissions.writeQuestions]) && (
                    <Button severity="info" icon="pi pi-pencil" rounded onClick={() => openModalToEditQuestion(question)}></Button>
                )}
            </>
        );
    };

    if (isError) {
        return <div>Error!</div>;
    }

    return (
        <>
            <div className="p-0 border-round border-2 border-orange-200" data-testId="scheduled-questions-list">
                <QuestionModalComponent
                    isVisible={isModalVisible}
                    isEdit={isEdit}
                    onModalClose={closeModal}
                    initialValue={selectedQuestion}
                ></QuestionModalComponent>
                <QuestionActionsComponent openModal={() => setIsModalVisible(true)}></QuestionActionsComponent>
            </div>
            <div className="p-0 border-round border-2 border-orange-200" data-testId="scheduled-questions-list">
                <DataTable
                    value={data}
                    loading={isLoading}
                    showGridlines
                    stripedRows
                    paginator
                    scrollable
                    scrollHeight="62vh"
                    rows={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    tableStyle={{ minWidth: "50%" }}
                >
                    <Column field="question" filter filterPlaceholder="Search by question" header="Question"></Column>
                    <Column field="level" sortable header="Level"></Column>
                    <Column field="category" sortable header="Category"></Column>
                    <Column field="hasTranslation" sortable header="Has translation" align={"center"} body={hasTranslationTemplate}></Column>
                    <Column field="" header="Actions" align={"center"} body={actionsTemplate}></Column>
                </DataTable>
            </div>
        </>
    );
};

export default QuestionListComponent;
