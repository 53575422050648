import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useAccessToken } from "../../hooks/useAccessToken";
import { useGetAllSurveys } from "../../api/apiClient";
import { Button } from "primereact/button";
import { ISurvey } from "../../models/survey/survey";
import { IFeedbackSurveyParams } from "../../models/survey/feedbackSurveyParams";

interface AskForFeedbackSubFormProps {
    channelId: string | undefined;
    jobParams: string | undefined;
    setJobParams: (value: string) => void;
}

const AskForFeedbackSubFormComponent: React.FC<AskForFeedbackSubFormProps> = ({ channelId, jobParams, setJobParams }) => {
    const token = useAccessToken();
    const { data: availableSurveyItems } = useGetAllSurveys(token);
    const parsedJobParams: IFeedbackSurveyParams | undefined = jobParams ? JSON.parse(jobParams) : undefined;
    const selectedSchedules: ISurvey[] = availableSurveyItems?.filter((survey) => parsedJobParams?.itemIds.includes(survey.id)) ?? [];
    const [selectedSurvey, setSelectedSurvey] = useState<ISurvey | undefined>();

    const actionsTemplate = (survey: ISurvey) => {
        return (
            <Button
                severity="info"
                icon="pi pi-trash"
                rounded
                onClick={() => {
                    removeSurveyFromJob(survey.id);
                }}
            ></Button>
        );
    };

    const selectionChanged = (surveysInJob: ISurvey[]): void => {
        const updatedJobParams: IFeedbackSurveyParams = {
            userId: channelId ?? "",
            itemIds: surveysInJob.map((survey) => survey.id),
        };

        setJobParams(JSON.stringify(updatedJobParams));
    };

    const addSurveyToJob = (): void => {
        if (selectedSurvey !== undefined && !selectedSchedules?.includes(selectedSurvey)) {
            const updatedSurveys = [...selectedSchedules, selectedSurvey];

            selectionChanged(updatedSurveys);
            setSelectedSurvey(undefined);
        }
    };

    const removeSurveyFromJob = (surveyId: string): void => {
        if (selectedSchedules.some((survey) => survey.id === surveyId)) {
            const updatedSurveys = selectedSchedules.filter((survey) => survey.id !== surveyId);

            selectionChanged(updatedSurveys);
        }
    };

    return (
        <>
            <Divider>
                <small>Survey items</small>
            </Divider>
            <div className="flex gap-5">
                <Dropdown
                    value={selectedSurvey}
                    onChange={(e) => {
                        setSelectedSurvey(e.value);
                    }}
                    options={availableSurveyItems || []}
                    optionLabel="description"
                    placeholder="Select a survey item"
                    className="w-5"
                />
                <Button type="button" label="Add" rounded onClick={addSurveyToJob}></Button>
            </div>
            {selectedSchedules && selectedSchedules.length > 0 && (
                <div className="flex">
                    <DataTable value={selectedSchedules} showGridlines stripedRows scrollable rows={4}>
                        <Column field="description"></Column>
                        <Column field="" body={actionsTemplate}></Column>
                    </DataTable>
                </div>
            )}
        </>
    );
};

export default AskForFeedbackSubFormComponent;
